export const events = {
  USER_ACTION: 'user_action',
  USER_ACTION_CTA: 'user_action.cta',
  USER_ACTION_PAGE_VIEW: 'user_action.page_view'
};

export const pages = {
  SEARCH: 'search',
  SEARCH_RESULTS: 'search_results',
  PROVIDER_PROFILE: 'provider_profile',
  LOCATION: 'location',
  CTA_MODAL: 'cta_modal',
  MAP_SEARCH: 'map_search'
};

export const placements = {
  PROVIDER_CARD: 'provider_card',
  NAV: 'nav',
  PROFILE: 'profile',
  SEARCH: 'search',
  SEARCH_RESULTS: 'search_results'
};

// param used to track booking source in DBW
export const BOOK_TRACKING_PARAM_NAME = 'book_src';

export const BOOK_TRACKING_VALUE_PROFILE = 'avail_tile_profile';
export const BOOK_TRACKING_VALUE_SEARCH = 'avail_tile_search';
