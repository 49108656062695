import { ExtendedProvider, Profile } from 'Src/types';

export const convertProfilesToProviders = (
  profiles: Profile[]
): ExtendedProvider[] =>
  profiles.map((profile: Profile) => ({
    ...profile.provider,
    // hide locations
    locations: [],
    has_provider_availability: !!profile.availability?.slots?.length
  }));
