import { useState, useEffect } from 'react';
import { datadogRum } from '@datadog/browser-rum';

const useLocalStorage = (key) => {
  const [value, setValue] = useState(null);

  useEffect(() => {
    try {
      // SSR-safe! No need to check for global `window` object, because `useEffect` only runs in the browser.
      const retrievedValue = window.localStorage.getItem(key);
      const parsedValue = JSON.parse(retrievedValue);
      setValue(parsedValue);
    } catch (e) {
      datadogRum.addError(e);
    }
  }, [key]);

  useEffect(() => {
    if (value) {
      try {
        window.localStorage.setItem(key, JSON.stringify(value));
      } catch (e) {
        datadogRum.addError(e);
      }
    }
  }, [key, value]);

  return [value, setValue];
};

export default useLocalStorage;
