import axios from 'axios';

import { Search } from '@kyruus/search-sdk';

import {
  getCommonTrackingParams,
  SEARCH_SHUFFLE_TOKEN,
  getTokenValue
} from 'Common/logging/tokens';

import { settingsSelector } from '../settings';
import { transformFilterUrlParamsForSearchSdk } from '../search-v9/actions/utils';
import { productNameSelector } from '../product-name';
import { convertProfilesToProviders } from './utils';

export const REQUEST_LOCATION = 'REQUEST_LOCATION';
export const RECEIVE_LOCATION = 'RECEIVE_LOCATION';
export const RECEIVE_LOCATION_ERROR = 'RECEIVE_LOCATION_ERROR';

export const REQUEST_PROVIDERS_BY_LOCATION =
  'REQUEST_MAP_PROVIDERS_BY_LOCATION';
export const RECEIVE_PROVIDERS_BY_LOCATION =
  'RECEIVE_MAP_PROVIDERS_BY_LOCATION';
export const RECEIVE_PROVIDERS_BY_LOCATION_ERROR =
  'RECEIVE_MAP_PROVIDERS_BY_LOCATION_ERROR';

function requestLocation() {
  return {
    type: REQUEST_LOCATION
  };
}

export function receiveLocation(responseData) {
  return {
    type: RECEIVE_LOCATION,
    payload: {
      locationId: responseData._result._entity_id,
      data: responseData._result
    }
  };
}

export function receiveLocationError(error) {
  return {
    type: RECEIVE_LOCATION_ERROR,
    payload: error
  };
}

export function requestProvidersByLocation(locationId) {
  return {
    type: REQUEST_PROVIDERS_BY_LOCATION,
    payload: {
      locationId
    }
  };
}

export function receiveProvidersByLocation(locationId, responseData) {
  return {
    type: RECEIVE_PROVIDERS_BY_LOCATION,
    payload: {
      locationId,
      data: {
        providers: convertProfilesToProviders(responseData._result),
        totalProviders: responseData._metadata.total_providers
      }
    }
  };
}

export function receiveProvidersByLocationError(locationId, error) {
  return {
    type: RECEIVE_PROVIDERS_BY_LOCATION_ERROR,
    payload: {
      locationId,
      error
    }
  };
}

/**
 * The function redux uses to fetch locations from location sevice. This
 * function is also passed as a prop to the location-summary component and
 * into the location-summary machine as part of its initial context. We do
 * this so we can use a single function to both update the redux store and
 * the machine context at the same time anytime we need to fetch a location.
 *
 * This function can be called during server-side rendering, in which case
 * it's being called from serverSideExecute and passed the incoming request
 * that's built as part of the request handling pipeline.
 *
 * @param {string} locationId the ID of the location we want to fetch
 * @param {Object} req the incoming request if we're rendering server-side
 * @returns object (the location from location service)
 * @throws Error is something goes wrong with the fetch
 */
export function fetchLocation(locationId, req) {
  return async (dispatch, getState) => {
    dispatch(requestLocation());
    let baseURL;
    let headers;
    const state = getState();
    const customerCode = state.customerCode;
    const url = `/${encodeURIComponent(
      customerCode
    )}/location/${encodeURIComponent((locationId || '').toUpperCase())}`;

    if (req) {
      // server-side rendering, we're going to hit the locations endpoint directly,
      // so grab what we need from the request (only ever passed from serverSideExecute)
      const appSettings = await req.getAppSettings();
      const publicJwt = await req.getPublicJwt();

      baseURL = `${appSettings.LOCATION_V1_URL}`;

      headers = {
        'x-consumer-groups': customerCode,
        authorization: `Bearer ${publicJwt}`
      };
    } else {
      // client-side rendering, we're going to proxy through PMC
      baseURL = `/api/v2/locations`;

      headers = {
        'x-consumer-groups': customerCode
      };
    }

    try {
      const locationResponse = await axios.get(`${baseURL}${url}`, {
        params: {
          fields: 'ALL'
        },
        headers,
        withCredentials: true
      });

      dispatch(receiveLocation(locationResponse.data));
      return locationResponse.data;
    } catch (error) {
      dispatch(receiveLocationError(error));
      throw null;
    }
  };
}

export function fetchProvidersByLocation(locationId, params = {}, req) {
  return async (dispatch, getState) => {
    const state = getState();

    const { config, customerCode } = state;

    const isServer = Boolean(req);
    const appSettings = settingsSelector(state);
    const serverSearchServiceUrl = appSettings.SEARCH_V9_URL;
    const clientSearchServiceUrl = '/api/searchservice-v9';
    const searchServiceUrl = isServer
      ? serverSearchServiceUrl
      : clientSearchServiceUrl;

    const searchClient = new Search({
      customerCode: customerCode,
      productName: productNameSelector(state),
      baseURL: searchServiceUrl
    });

    dispatch(requestProvidersByLocation(locationId));

    let fullParams = {
      context: config.index || customerCode,
      // this is done as a 'clear' search
      // i.e., not carrying over params from the search results page
      // `sort` is the only param users can change on the locations page
      sort: params.sort || '',
      // we're grabbing 1 page of results at a time and adding to it with each 'load more'
      // essentially, incrementing the per_page param and always getting the 'first' page
      page: 1,
      // always filter for providers @ the current location
      filter: transformFilterUrlParamsForSearchSdk([
        `locations.primary_marketable_location_id:${locationId}`
      ]),
      provider_fields: ['-clinical_keywords'].join(',')
    };

    // append options `components`
    if (config?.search_params?.geocoding?.components?.administrative_area) {
      fullParams.components = `administrative_area:${config.search_params.geocoding.components.administrative_area}`;
    }

    if (req) {
      // server-side rendering, we're going to hit the locations endpoint directly,
      // so grab what we need from the request (only ever passed from serverSideExecute)
      fullParams = {
        ...fullParams,
        ...getCommonTrackingParams({ req }),
        shuffle_seed: getTokenValue({ req, token: SEARCH_SHUFFLE_TOKEN }),

        // per_page will be a multiple of appSettings.PROVIDERS_PER_PAGE
        per_page: params.page * appSettings.PROVIDERS_PER_PAGE
      };
    } else {
      fullParams = {
        ...fullParams,
        ...getCommonTrackingParams(),
        shuffle_seed: getTokenValue({ token: SEARCH_SHUFFLE_TOKEN }),
        // per_page will be a multiple of appSettings.PROVIDERS_PER_PAGE
        per_page: params.page * appSettings.PROVIDERS_PER_PAGE
      };
    }
    try {
      const searchResponse = await searchClient.getProviders(fullParams);

      const data = searchResponse.data;

      // currently, the reducer doesn't save this data as providers-on-locations doesn't require SSR
      dispatch(receiveProvidersByLocation(locationId, data));
      // return the response data for any service (xstate) that might
      // be fetching data through redux
      return data;
    } catch (error) {
      dispatch(receiveProvidersByLocationError(locationId, error));
      // return null if something goes wrong, up to any service (xstate)
      // fetching data through redux to handle this
      throw null;
    }
  };
}
