import React from 'react';
import _get from 'lodash/get';
import { defineMessages } from '@kyruus/intl';
import PoweredByKyruusHealth from '@kyruus/react-ikons/PoweredByKyruusHealth';

import { makeInternational } from '../utils/intl-components';
import { PoweredByLogoWrapper } from './powered-by-logo-styles';

const messages = defineMessages({
  poweredbykyruus: {
    id: 'aria.label.poweredbykyruus',
    description: 'Title for the "Powered by Kyruus Health" logo svg',
    defaultMessage: 'Powered by Kyruus Health logo'
  },
  poweredbykyruuslink: {
    id: 'aria.label.link.poweredbykyruus',
    description: 'Accessibility label for the Powered by Kyruus Health link',
    defaultMessage: 'Learn more about Kyruus Health'
  }
});

const IntlAnchor = makeInternational('a');
const IntlPoweredByKyruusHealth = makeInternational(PoweredByKyruusHealth);

export default function PoweredByLogo({ config, className = undefined }) {
  if (_get(config, 'show_powered_by_kyruus_logo', true)) {
    return (
      <PoweredByLogoWrapper
        id="powered-by-kyruus-logo-wrapper"
        className={className}
      >
        <IntlAnchor
          id="powered-by-kyruus-logo"
          className="powered-by-link"
          href="https://www.kyruus.com"
          intlProps={{
            'aria-label': { descriptor: messages.poweredbykyruuslink }
          }}
        >
          <IntlPoweredByKyruusHealth
            width="140px"
            height="23.6px"
            intlProps={{
              title: { descriptor: messages.poweredbykyruus }
            }}
          />
        </IntlAnchor>
      </PoweredByLogoWrapper>
    );
  } else {
    return null;
  }
}
