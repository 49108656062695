import { RECEIVE_LOCATION, RECEIVE_PROVIDERS_BY_LOCATION } from './actions';

import { CLEAR_DATA } from '../actions';

export const DEFAULT_STATE = {
  locationsById: {},
  providersByLocationId: {}
};

const mapLocationData = (locationId, data) => {
  return {
    [locationId.toUpperCase()]: data
  };
};

export default function locationReducer(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case RECEIVE_LOCATION:
      return {
        ...state,
        locationsById: {
          ...state.locationsById,
          ...mapLocationData(action.payload.locationId, action.payload.data)
        }
      };
    case RECEIVE_PROVIDERS_BY_LOCATION:
      return {
        ...state,
        providersByLocationId: {
          ...state.providersByLocationId,
          ...mapLocationData(action.payload.locationId, action.payload.data)
        }
      };
    case CLEAR_DATA:
      return {};
    default:
      return state;
  }
}
